import { MatxLoadable } from "matx";

const Profile = MatxLoadable({
  loader: () => import("./Profile"),
});
const Verify = MatxLoadable({
  loader: () => import("./Verify"),
});
const Password = MatxLoadable({
  loader: () => import("./Password"),
});
const Display = MatxLoadable({
  loader: () => import("./Display"),
});

const utilitiesRoutes = [
  {
    path: "/profile/verify",
    component: Verify,
  },
  {
    path: "/profile/account",
    component: Profile,
  },
  {
    path: "/profile/password",
    component: Password,
  },
  {
    path: "/utilities/display",
    component: Display,
  },
];

export default utilitiesRoutes;
