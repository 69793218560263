import React, { Component } from "react";
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Button,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import { connect } from "react-redux";
import Axios from "axios";
import url from "../../../config";
import { PropTypes } from "prop-types";
import { loginWithEmailAndPassword } from "../../redux/actions/LoginActions";

class SignUp extends Component {
  state = {
    inputsField: {
      email: "",
      name: "",
      password: "",
      country: "",
      agreement: "",
      password_confirmation: "",
    },
    countriesData: [],
    loading: false,
    errorMsg: null,
  };

  handleChange = (event) => {
    event.persist();
    this.setState({
      inputsField: {
        ...this.state.inputsField,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleFormSubmit = (event) => {
    this.setState({
      errorMsg: null,
      loading: true,
    });
    Axios.post(url.BASE_DOMAIN + "/register", this.state.inputsField)
      .then((data) => {
        this.setState({ loading: false });
        this.props.loginWithEmailAndPassword({ ...this.state.inputsField });
      })
      .catch((error) => {
        var err_msg = "";
        if (error.response) {
          // Request made and server responded
          if (error.response.data.errors) {
            var err = error.response.data.errors;
            Object.entries(err).forEach(([key, value]) => {
              err_msg += value + "<br/>";
            });
          } else {
            console.log(error.response.data);
            err_msg = "An error has occured. Please try again";
          }
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          err_msg = "An error has occured. Please try again";
        }
        this.setState({
          errorMsg: err_msg,
          loading: false,
        });
      });
  };
  componentDidMount = () => {
    Axios.get(url.BASE_DOMAIN + "/api/get_countries").then((data) => {
      this.setState({ countriesData: data.data });
    });
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== this.state.inputsField.password) {
        return false;
      }
      return true;
    });
  };

  componentWillUnmount() {
    // remove rule when it is not needed
    ValidatorForm.removeValidationRule("isPasswordMatch");
  }

  render() {
    let {
      country,
      email,
      password,
      password_confirmation,
      name,
      phone,
      currency,
      acct_type,
    } = this.state.inputsField;
    return (
      <div className="signup flex flex-center w-100 py-30">
        <div className="p-8">
          <Card className="signup-card position-relative y-center">
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div className="p-32  text-center flex-center bg-light-gray  h-100">
                  <div className="row">
                    <h2>Account Registration</h2>
                    <small>Register with us now to start trading</small>
                  </div>
                  <div className="row flex pt-30 flex-middle">
                    <a href={url.BASE_DOMAIN}>
                      <img src="/logo.png" alt="" />
                    </a>
                  </div>
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12}>
                <div className="p-36 h-100">
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.handleFormSubmit}
                    onError={(errors) => null}
                  >
                    {this.state.errorMsg && (
                      <div
                        className="p-20 mb-10"
                        style={{
                          backgroundColor: "#FFDEDE",
                          color: "#FF0000",
                          borderRadius: 10,
                        }}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: this.state.errorMsg,
                          }}
                        ></span>
                      </div>
                    )}
                    <TextValidator
                      className="mb-24 w-100"
                      variant="outlined"
                      label="Fullname"
                      onChange={this.handleChange}
                      type="text"
                      name="name"
                      value={name}
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                    />
                    <TextValidator
                      className="mb-24 w-100"
                      variant="outlined"
                      label="Email"
                      onChange={this.handleChange}
                      type="email"
                      name="email"
                      value={email}
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        "this field is required",
                        "email is not valid",
                      ]}
                    />
                    <TextValidator
                      className="mb-24 w-100"
                      variant="outlined"
                      label="Phone number"
                      onChange={this.handleChange}
                      type="text"
                      name="phone"
                      value={phone}
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                    />
                    <SelectValidator
                      className="mb-24 w-100"
                      variant="outlined"
                      label="Country"
                      onChange={this.handleChange}
                      name="country"
                      value={country}
                      validators={["required"]}
                      errorMessages={["Your country is required"]}
                    >
                      {this.state.countriesData.map((country) => (
                        <MenuItem value={country.iso} key={country.id}>
                          {country.nicename}
                        </MenuItem>
                      ))}
                    </SelectValidator>

                    <SelectValidator
                      className="mb-24 w-100"
                      variant="outlined"
                      label="Account type"
                      onChange={this.handleChange}
                      name="acct_type"
                      value={acct_type}
                      validators={["required"]}
                      errorMessages={["Select an account type"]}
                    >
                      <MenuItem value="Starter">Starter - From $500</MenuItem>
                      <MenuItem value="Bronze">Bronze - From $1000</MenuItem>
                      <MenuItem value="Silver">Silver - From $3000</MenuItem>
                      <MenuItem value="Gold">Gold - From $5000</MenuItem>
                      <MenuItem value="Crude Oil Package">
                        Crude Oil Package - $12000
                      </MenuItem>
                      <MenuItem value="Platinum">
                        Platinum - From $15000
                      </MenuItem>
                      <MenuItem value="Legend">Legend - From $25000</MenuItem>
                    </SelectValidator>
                    <SelectValidator
                      className="mb-24 w-100"
                      variant="outlined"
                      label="Prefered currency"
                      onChange={this.handleChange}
                      name="currency"
                      value={currency}
                      validators={["required"]}
                      errorMessages={["Select your prefered currency"]}
                    >
                      <MenuItem value="Starter">$ USD</MenuItem>
                      <MenuItem value="Bronze">£ GPB</MenuItem>
                      <MenuItem value="Silver">&#8383; BTC </MenuItem>
                      <MenuItem value="Gold">€ EUR</MenuItem>
                    </SelectValidator>

                    <TextValidator
                      className="mb-16 w-100"
                      label="Password"
                      variant="outlined"
                      onChange={this.handleChange}
                      name="password"
                      type="password"
                      value={password}
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                    />
                    <TextValidator
                      className="mb-16 w-100"
                      label="Confirm Password"
                      variant="outlined"
                      onChange={this.handleChange}
                      name="password_confirmation"
                      type="password"
                      value={password_confirmation}
                      validators={["required", "isPasswordMatch"]}
                      errorMessages={[
                        "this field is required",
                        "password didn't match",
                      ]}
                    />
                    <FormControlLabel
                      className="mb-16"
                      name="agreement"
                      onChange={this.handleChange}
                      control={<Checkbox />}
                      label="I have read and agree to the terms of service."
                    />
                    <div className="flex flex-middle">
                      <div style={{ position: "relative" }}>
                        <Button
                          className="capitalize"
                          variant="contained"
                          color="primary"
                          disabled={this.state.loading}
                          type="submit"
                        >
                          Sign up
                        </Button>
                        {this.state.loading && (
                          <CircularProgress
                            size={24}
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              marginTop: -12,
                              marginLeft: -12,
                            }}
                          />
                        )}
                      </div>
                      <span className="ml-16 mr-8">or</span>
                      <Button
                        className="capitalize"
                        onClick={() => this.props.history.push("/auth/signin")}
                      >
                        Sign in
                      </Button>
                    </div>
                  </ValidatorForm>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // setUser: PropTypes.func.isRequired
  loginWithEmailAndPassword: PropTypes.func.isRequired,
  login: state.login,
});

export default connect(mapStateToProps, { loginWithEmailAndPassword })(SignUp);
