import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const BasicForm = MatxLoadable({
  loader: () => import("./Deposit"),
});

const EditorForm = MatxLoadable({
  loader: () => import("./Withdraw"),
});

const TransHistory = MatxLoadable({
  loader: () => import("./TransHistory"),
});

const formsRoutes = [
  {
    path: "/wallet/deposit",
    component: BasicForm,
    // auth: authRoles.sa,
  },
  {
    path: "/wallet/withdraw",
    component: EditorForm,
  },
  { path: "/transactions", component: TransHistory },
];

export default formsRoutes;
