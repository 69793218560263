import Axios from "axios";
import axios from "axios";
import localStorageService from "./localStorageService";
import url from "../../config";

class JwtAuthService {
  // Dummy user object just for the demo
  // user = {
  //   userId: "1",
  //   role: "ADMIN",
  //   displayName: "Jason Alexander",
  //   email: "jasonalexander@gmail.com",
  //   photoURL: "/assets/images/face-6.jpg",
  //   age: 25,
  //   token: "faslkhfh423oiu4h4kj432rkj23h432u49ufjaklj423h4jkhkjh",
  // };

  // You need to send http request with email and passsword to your server in this method
  // Your server will return user object & a Token
  // User should have role property
  // You can define roles in app/auth/authRoles.js
  loginWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      // setTimeout(() => {
      //   // resolve(this.user);
      // }, 1000);
      axios.get(`${url.BASE_DOMAIN}/sanctum/csrf-cookie`).then((response) => {
        axios
          .post(`${url.BASE_DOMAIN}/sacntum/login`, { email, password })
          .then((data) => {
            // console.log(data.data);
            resolve(data.data);
          })
          .catch((error) => reject(error));
      });
    }).then((data) => {
      // Login successful
      // Save token
      this.setSession(data.token);
      // Set user
      // this.setUser(data);
      return data;
    });
  };

  // You need to send http requst with existing token to your server to check token is valid
  // This method is being used when user logged in & app is reloaded
  loginWithToken = () => {
    return new Promise((resolve, reject) => {
      var token = localStorage.getItem("jwt_token");
      axios
        .get(`${url.BASE_DOMAIN}/api/user`, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((data) => {
          // console.log(data.data);
          resolve(data.data);
        })
        .catch((error) => reject(error));
    }).then((data) => {
      // Token is valid
      // console.log(data);
      // this.setSession(data.token);
      // this.setUser(data);
      return data;
    });
  };

  logout = () => {
    this.setSession(null);
    this.removeUser();
  };

  // Set token to all http request header, so you don't need to attach everytime
  setSession = (token) => {
    if (token) {
      localStorage.setItem("jwt_token", token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      localStorage.removeItem("jwt_token");
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  // Save user to localstorage
  setUser = (user) => {
    localStorageService.setItem("auth_user", user);
  };
  // Remove user from localstorage
  removeUser = () => {
    localStorage.removeItem("auth_user");
  };
}

export default new JwtAuthService();
