import React, { useEffect, useState } from "react";
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Button,
  withStyles,
  CircularProgress,
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";
import url from "../../../config";

import { loginWithEmailAndPassword } from "../../redux/actions/LoginActions";

const styles = (theme) => ({
  wrapper: {
    position: "relative",
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

const SignIn = (props) => {
  const [inputValue, setInputValue] = useState({ email: "", password: "" });
  const [errorMsg, setErrorMsg] = useState(null);

  const handleChange = (event) => {
    event.persist();
    setInputValue({
      ...inputValue,
      [event.target.name]: event.target.value,
    });
  };
  const handleFormSubmit = (event) => {
    setErrorMsg(null);
    props.loginWithEmailAndPassword({ ...inputValue });
  };

  let { login } = props;

  useEffect(() => {
    var err_msg = "";
    if (login.error.response) {
      if (login.error.response.data.errors) {
        var err = login.error.response.data.errors;
        Object.entries(err).forEach(([key, value]) => {
          err_msg += value + "<br/>";
        });
      } else {
        console.log(login.error.response.data);
        err_msg = "An error has occured. Please try again";
      }
      setErrorMsg(err_msg);
    }
  }, [login.error]);

  let { email, password } = inputValue;
  let { classes } = props;

  return (
    <div className="signup flex flex-center w-100 h-100vh">
      <div className="p-8">
        <Card className="signup-card position-relative y-center">
          <Grid container>
            <Grid item lg={5} md={5} sm={5} xs={12}>
              <div className="p-32 flex flex-center flex-middle h-100">
                <a href={url.BASE_DOMAIN}>
                  <img src="/logo.png" alt="" />
                </a>
              </div>
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              <div className="p-36 h-100 bg-light-gray position-relative">
                <ValidatorForm
                  onSubmit={handleFormSubmit}
                  onError={(errors) => null}
                >
                  {errorMsg && (
                    <div className="alert alert-danger">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: errorMsg,
                        }}
                      ></span>
                    </div>
                  )}
                  <TextValidator
                    className="mb-24 w-100"
                    variant="outlined"
                    label="Email"
                    onChange={handleChange}
                    type="email"
                    name="email"
                    value={email}
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      "this field is required",
                      "email is not valid",
                    ]}
                  />
                  <TextValidator
                    className="mb-16 w-100"
                    label="Password"
                    variant="outlined"
                    onChange={handleChange}
                    name="password"
                    type="password"
                    value={password}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                  />
                  <FormControlLabel
                    className="mb-8"
                    name="agreement"
                    onChange={handleChange}
                    control={<Checkbox checked />}
                    label="I have read and agree to the terms of service."
                  />
                  <div className="flex flex-middle mb-8">
                    <div className={classes.wrapper}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={login.loading}
                        type="submit"
                      >
                        Sign in to Enter Dashboard
                      </Button>
                      {login.loading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                    <span className="ml-16 mr-8">or</span>
                    <Button
                      className="capitalize"
                      onClick={() => props.history.push("/auth/register")}
                    >
                      Sign up
                    </Button>
                  </div>
                  <Button
                    className="text-primary"
                    onClick={() => props.history.push("/auth/forgot-password")}
                  >
                    Forgot password?
                  </Button>
                </ValidatorForm>
              </div>
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginWithEmailAndPassword: PropTypes.func.isRequired,
  login: state.login,
});
export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, { loginWithEmailAndPassword })(SignIn))
);
